export interface DynamicObject {
  [key: string]: any;
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}
export class Alert {
  type!: AlertType;
  message!: string;
}
export interface Insights {
  name: string;
  series: Series[];
}
export interface Series {
  value: number;
  name: string;
  month: string;
}

export interface Status {
  name: string;
  value: number;
}

export class UserGroupDto {
  id?: number;
  name?: string;
  description?: string | null;
  constructor(data?: Partial<UserGroupDto>) {
    Object.assign(this, data);
  }
}

export class UserRolePageDto {
  id?: number;
  name?: string;
  route?: string;
  icon?: string | null;
  order?: number;
  ismenu?: boolean;
  constructor(data?: Partial<UserRolePageDto>) {
    Object.assign(this, data);
  }
}

export class UserRoleDto {
  id?: number;
  name?: string;
  description?: string | null;
  priority?: number;
  order?: number;
  defaultpage?: UserRolePageDto;
  defaultpageid?: number;
  parentid?: number | null;
  parent?: UserRoleDto | null;
  constructor(data?: Partial<UserRoleDto>) {
    Object.assign(this, data);
  }
}

export class UserDto {
  id?: number;
  name?: string;
  description?: string | null;
  priority?: number;
  order?: number;
  defaultpage?: UserRolePageDto;
  defaultpageid?: number;
  parentid?: number | null;
  parent?: UserRoleDto | null;
  constructor(data?: Partial<UserDto>) {
    Object.assign(this, data);
  }
}
