import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Alert, AlertType } from '../common/common.entity';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn:'root'
})
export class AlertService {
  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private router: Router,private toast:MessageService ) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, keepAfterRouteChange);
  }

  info(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, keepAfterRouteChange);
  }

  warn(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Warning, message, keepAfterRouteChange);
  }

  alert(type: AlertType, message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Alert>{ type: type, message: message });
  }

  clear() {
    // clear alerts
    this.subject.next(null);
  }

showToast(severity:any,header?:string,content?:string) {
  // Toasting Types
// { severity: 'info', summary: 'Info', detail: 'Message Content' }
// { severity: 'warn', summary: 'Warn', detail: 'Message Content' }
// { severity: 'error', summary: 'Error', detail: 'Message Content' }
// { severity: 'contrast', summary: 'Error', detail: 'Message Content' }
// { severity: 'secondary', summary: 'Secondary', detail: 'Message Content' }
  this.toast.add({ severity: severity, summary: header || 'Success', detail: content || '' });
}

}
